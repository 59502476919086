






































































































































































































import numericFilter from "Utilities/numericFilter";
import { curveTypeFormula } from "@/consts/curveConfigDefaults";
import { CurveFunction, PowerSpeedCurveConfig, PowerSpeedCurveData, PowerSpeedCurveParameter } from "@/types/PowerSpeedCurveConfig";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class PowerSpeedCurveDataEditDialog extends Vue {
  @PropSync("value", { required: true }) isOpen!: boolean;
  @PropSync("maxWidth", { default: 1000 }) dialogWidth!: number;
  @Prop({ required: true }) config!: PowerSpeedCurveConfig;
  @Prop({ required: true }) curveData!: PowerSpeedCurveData;
  @Prop({ required: true }) curveDataIndex!: number;

  clonedCurveData: PowerSpeedCurveData | null = null;
  parameters: string[] = ["A", "B", "C", "D"];

  get curveOptions(): string[] {
    return Object.keys(CurveFunction);
  }

  get showActions(): boolean {
    if (!this.clonedCurveData) return false;
    return this.clonedCurveData.curveFunction === CurveFunction.Polynomial;
  }

  get selectedCurveFunction(): CurveFunction | undefined {
    return this.clonedCurveData?.curveFunction;
  }

  get selectedCurveFunctionFormula(): string {
    if (!this.selectedCurveFunction) return "";
    return curveTypeFormula[this.selectedCurveFunction];
  }

  get curveParameters(): PowerSpeedCurveParameter[] {
    if (!this.clonedCurveData || !this.selectedCurveFunction) return [];
    return this.clonedCurveData.powerSpeedCurveParameters;
  }

  @Watch("isOpen")
  handleCurveDataChange(): void {
    this.clonedCurveData = JSON.parse(JSON.stringify(this.curveData));
  }

  @Watch("selectedCurveFunction")
  handleCurveFunctionChange(): void {
    if (!this.clonedCurveData) return;
    const parametersNeeded = ["A", "B"];
    switch (this.selectedCurveFunction) {
      case CurveFunction.Power:
        this.addMissingParameters(parametersNeeded);
        this.clonedCurveData.powerSpeedCurveParameters = this.clonedCurveData.powerSpeedCurveParameters.slice(0, 2);
        break;
      case CurveFunction.Hoerl:
        parametersNeeded.push("C");
        this.addMissingParameters(parametersNeeded);
        this.clonedCurveData.powerSpeedCurveParameters = this.clonedCurveData.powerSpeedCurveParameters.slice(0, 3);
        break;
      case CurveFunction.Polynomial:
        parametersNeeded.push("C");
        this.addMissingParameters(parametersNeeded);
        this.clonedCurveData.powerSpeedCurveParameters = this.clonedCurveData?.powerSpeedCurveParameters.slice(0, 4);
        break;
      case CurveFunction.Rational:
        parametersNeeded.push("C");
        parametersNeeded.push("D");
        this.addMissingParameters(parametersNeeded);
        this.clonedCurveData.powerSpeedCurveParameters = this.clonedCurveData?.powerSpeedCurveParameters.slice(0, 4);
        break;
      default:
        break;
    }
  }

  addMissingParameters(parametersNeeded: string[]): void {
    const missingParameters = parametersNeeded.filter(p => !this.clonedCurveData?.powerSpeedCurveParameters.some(c => c.constant === p));
    missingParameters.forEach(parameter => {
      this.clonedCurveData?.powerSpeedCurveParameters.push({ id: 0, constant: parameter, a0: 0, a1: 0, a2: 0 });
    });
  }

  numericFilter(event: any): number {
    return numericFilter(event);
  }

  isAddConstantEnabled(): boolean {
    return this.curveParameters.length < this.parameters.length;
  }

  isRemoveConstantEnabled(): boolean {
    return this.curveParameters.length > 3;
  }

  addParameter(): void {
    if (!this.clonedCurveData) return;
    if (this.clonedCurveData.powerSpeedCurveParameters.length >= this.parameters.length) return;
    const parameter = this.parameters[this.clonedCurveData.powerSpeedCurveParameters.length];
    this.clonedCurveData?.powerSpeedCurveParameters.push({ id: 0, constant: parameter, a0: 0, a1: 0, a2: 0 });
  }

  removeParameter(): void {
    if (!this.clonedCurveData) return;
    if (this.clonedCurveData.powerSpeedCurveParameters.length <= 3) return;
    this.clonedCurveData?.powerSpeedCurveParameters.pop();
  }

  saveChanges(): void {
    this.isOpen = false;
    this.$emit("onSave", this.clonedCurveData, this.curveDataIndex);
  }

  discardChanges(): void {
    this.isOpen = false;
  }
}
